import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Managing Campaigns`}</h1>
    <p>{`Create, configure and delete campaigns in Engagement Studio.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e8d9c0cdf49708272694428963465941/47b67/es-adcenter-campaigns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB50lEQVQoz12RS09TQRiG+ytcqIm3pFZaWoqWi2JM3Bk2Xha9YBETE8qtQIG2FGnRBYYeMAHXpjfanpkz51Za/XmP6akuZPFk8r3JPJl3Pl9sUzG3ZzO5IRlbs4i8KxF5PEM0vsfUUoXppUOPULLIo3ieYLLgEUqNCC8UmUyXiC2VmP54gG9qS/F8z2ZiTR8J3+4zHp1iNl0kVTgjka+SKmjE81USBY1EoUqyqJG6xsK+xsvM0Uj4dMckstolnHXwv9rkxu37rJbPGPQcpBBIKTCVQc91uOr1cB0H17HpX/X+Y+Xr+Ug4t2sxm1OeMDCf4+ZdP6uV7/waDFBKYRgKy7JwXBfbdhBCUGs0kcr0Mst2+N2/Inv8A9/MtmI2ZxLMdAht2ATmt7l1z8/y4SlS2Z5IFwa2baNMi7Zu4LouHV0ipKLTFejSoN9zyX67wDes+2zHJLjcIbRuMf46TyD8hJWy5l1qXV5Sr9fQu12arTY/6y10vUu9VqPZbNJoNGh32hhCJ3t8ji+2aRBdl4Qyf4VvCoxNxDzh8M+klF7t4UuHmOa/0xxlpqLe6iCEZOvkYljZJLImvMrj3lK2uPPgIS8+lUiVTkkUT0iVqiwcVHn/WSN9qJEuayxWRnw4Gs4nLFaqzO9+4Q+Hwo96pIBjDgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e8d9c0cdf49708272694428963465941/e93cc/es-adcenter-campaigns.webp 300w", "/static/e8d9c0cdf49708272694428963465941/b0544/es-adcenter-campaigns.webp 600w", "/static/e8d9c0cdf49708272694428963465941/68fc1/es-adcenter-campaigns.webp 1200w", "/static/e8d9c0cdf49708272694428963465941/dcb2d/es-adcenter-campaigns.webp 1664w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e8d9c0cdf49708272694428963465941/eed55/es-adcenter-campaigns.png 300w", "/static/e8d9c0cdf49708272694428963465941/7491f/es-adcenter-campaigns.png 600w", "/static/e8d9c0cdf49708272694428963465941/8537d/es-adcenter-campaigns.png 1200w", "/static/e8d9c0cdf49708272694428963465941/47b67/es-adcenter-campaigns.png 1664w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e8d9c0cdf49708272694428963465941/8537d/es-adcenter-campaigns.png",
              "alt": "es adcenter campaigns",
              "title": "es adcenter campaigns",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      